import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Row from "react-bootstrap/Row";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

import Permissions from '../../util/Permissions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      pending: false,
      logged_in: false
    };

    this.changeMail = this.changeMail.bind(this);
    this.changePass = this.changePass.bind(this);
    this.login = this.login.bind(this);
  }

  render() {
    return (
      <Row>
        <Card className="login-box" md={4}>
          <h1>TDM Ledenzone</h1>
          <p>Log in om je sessie te beginnen.</p>

          <form onSubmit={this.login}>
            <div className="form-group">
              <input id="email" name="email" type="email" value={this.state.email} onChange={this.changeMail} className="form-control" placeholder="Email" autoComplete="email" required/>
            </div>

            <div className="form-group">
              <input id="pass" name="pass" type="password" value={this.state.pass} onChange={this.changePass} className="form-control" placeholder="Wachtwoord" autoComplete="current-password" required/>
            </div>

            <div className="form-group">
              {(this.state.pending) ? (
                <Loader />
              ) : (
                <button className="btn btn-primary btn-block btn-flat">
                  Inloggen
                </button>
              )}
            </div>
          </form>

          <div className="d-flex justify-content-between">
            <Link to="/register">Registreren</Link>
            <Link to="/forgotpass">Wachtwoord Vergeten</Link>
          </div>
        </Card>
      </Row>
    );
  }

  changeMail(event) {
    this.setState({email: event.target.value});
  }

  changePass(event) {
    this.setState({pass: event.target.value});
  }

  async login(e) {
    e.preventDefault();

    if (!this.state.email || this.state.email === "") {
      toast.error("Gelieve een mail in te vullen!");
      return;
    }

    if (!this.state.pass || this.state.pass === "") {
      toast.error("Gelieve een wachtwoord in te vullen!");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post("/api/login", {
      email: this.state.email,
      pass: this.state.pass
    }).then(async (response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        const permissions = await Permissions(response.data.user_id);

        if (!permissions) {
          toast.error("Er is iets fout gegaan bij het inloggen. Indien dit blijft gebeuren gelieve dit te melden!");
        }

        this.setState({
          logged_in: true
        });

        const user = {
          id: response.data.user_id,
          name: response.data.name,
          first_name: response.data.first_name,
          permissions: permissions,
          password_token: response.data.password_token,
          settings_token: response.data.settings_token,
          groups: response.data.groups
        };
        this.props.onLogin(user);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het inloggen. Indien dit blijft gebeuren gelieve dit te melden!");

    }).finally(() => {
      if (!this.state.logged_in) {
        this.setState({
          pending: false
        });
      }
    });
  }
}

export default Login;