import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import ReactHtmlParser from 'react-html-parser';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons/faNewspaper";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/nl';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import Card from "../../components/Card";
import Loader from "../../components/Loader";
import {faSearch} from '@fortawesome/free-solid-svg-icons';

class Reservation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reservation: null,
      payment_update: false,
      delete_update: false,
      resend_update: false
    };

    this.setPayed = this.setPayed.bind(this);
    this.setUnpayed = this.setUnpayed.bind(this);
    this.allowRefund = this.allowRefund.bind(this);
    this.delete = this.delete.bind(this);
    this.resendConfirmation = this.resendConfirmation.bind(this);
  }

  componentDidMount() {
    this.fetchReservations();
  }

  fetchReservations() {
    axios.get("/api/reservation/" + this.props.match.params.reservation_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            reservation: response.data.reservation,
            show: response.data.show,
            pricing: response.data.pricing
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de reservatie niet ophalen.");
      });
  }

  render() {
    return (
      <div>

        {(this.state.reservation && this.state.reservation.removed) ? (
          <Row>
            <Col md={1} />
            <Col md={10}>
              <Alert variant="danger" className="mt-3"><h3>Deze reservatie is verwijderd!</h3></Alert>
            </Col>
          </Row>
        ) : ''}

        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Reservatie {(this.state.reservation) ? this.state.reservation.id : ''}</h1>
            {(this.state.reservation) ? (
              <p>
                {this.state.reservation.name} {this.state.reservation.firstName}
                <br />
                {this.state.reservation.street}
                <br />
                {this.state.reservation.postal} {this.state.reservation.city}
                <br />
                &#9993; {this.state.reservation.email}
                <br />
                &#9742; {this.state.reservation.phone}
                <br />
                <FontAwesomeIcon icon={faNewspaper} /> {(this.state.reservation.newsletter) ? 'Wilt nieuwsbrief' : 'Geen nieuwsbrief'}
                <br />
                <FontAwesomeIcon icon={faSearch} /> {(this.state.reservation.found_on) ? this.state.reservation.found_on : ''}
              </p>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>

        {(this.state.reservation) ? (
          <div>

            {(this.state.reservation.remarks && this.state.reservation.remarks !== "") ? (
            <Row>
              <Col md={1} />
              <Card md={10}>
                <h2>Opmerkingen</h2>
                <p>
                  { ReactHtmlParser(this.state.reservation.remarks) }
                </p>
              </Card>
            </Row>
            ) : ('')}

            {(this.state.reservation.manual) ? (
              <Row>
                <Col md={1} />
                <Card md={10}>
                  <h2>Manuele reservatie</h2>
                  {(!this.state.reservation.payed) ? (
                    <div>
                      <p>
                        Reservatie nog niet betaald
                      </p>
                      {(this.state.payment_update) ? <Loader /> : <Button className="btn btn-success" onClick={this.setPayed}>Markeer betaald</Button>}
                    </div>
                  ) : (
                    <div>
                      <p>
                        Reservatie betaald
                      </p>
                      {(this.state.payment_update) ? <Loader /> : <Button className="btn btn-danger" onClick={this.setUnpayed}>Markeer onbetaald</Button>}
                    </div>
                  )}
                </Card>
              </Row>
            ) : (
              <Row>
                <Col md={1} />
                <Card md={10}>
                  <h2>Gegevens Betaling</h2>
                  <p>
                    Status betaling: {(this.state.reservation.refunded) ? (<b>TERUGBETAALD</b>) : (this.state.reservation.status)}
                    <br />
                    Betaal methode: {this.state.reservation.payment_method}
                    <br />
                    Betalingsid: {this.state.reservation.payment_id}
                  </p>
                  {(this.props.user.permissions.indexOf("webadmin") > -1) ? (
                    (this.state.reservation.refundable) ? (
                      <p>
                        <b>KAN WORDEN TERUG BETAALD</b>
                      </p>
                    ) : (
                      (this.state.payment_update) ? <Loader /> : <Button className="btn btn-danger" onClick={this.allowRefund}>Allow Refund</Button>
                    )
                  ) : null}
                </Card>
              </Row>
            )}

            <Row>
              <Col md={1} />
              <Card md={10}>
                <h2>Bestelling</h2>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th style={{width: '10%'}}>Aantal</th>
                      <th style={{width: '50%'}}>Beschrijving</th>
                      <th style={{width: '25%'}}>Prijs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.reservation.tickets}
                      </td>
                      <td>
                        {this.state.show.name} - <Moment tz="Europe/Brussels" format="dddd DD MMMM YYYY - HH:mm" locale="nl">{Number(this.state.show.show_time)}</Moment>
                      </td>
                      <td>
                        &euro;{this.state.pricing.total}
                      </td>
                    </tr>

                    {this.getSubscriptions()}
                    {this.getUitpasses()}
                    {this.getReductions()}

                    {(this.state.reservation.early_bird) ? (
                      <tr>
                        <td />
                        <td>
                          10% vroegboekkorting
                        </td>
                        <td>
                          &euro;{this.state.pricing.earlyBird}
                        </td>
                      </tr>
                    ) : <tr />}

                    <tr>
                      <td />
                      <td className="text-right"><b>TOTAAL</b></td>
                      <td><b>&euro;{this.state.pricing.subTotal}</b></td>
                    </tr>

                  </tbody>
                </Table>
              </Card>
            </Row>

            {(this.state.reservation.removed) ? ('') : (
                <Row>
                  <Col md={1} />
                  <Card md={10}>
                    <h2>Bevestigingsmail opnieuw verzenden</h2>
                    <p>
                      <b>Dit zal de bevestigingsmail opnieuw verzenden, gebruik dit enkel indien echt nodig!</b>
                    </p>
                    {(this.state.resend_update) ? <Loader /> : <Button className="btn btn-info" onClick={this.resendConfirmation}>Opnieuw Verzenden</Button>}
                  </Card>
                </Row>
            )}

            {(this.state.reservation.removed) ? ('') : (
              <Row>
                <Col md={1} />
                <Card md={10}>
                  <h2>Reservatie verwijderen</h2>
                  <p>
                    <b>Opgepast, deze actie kan niet ongedaan gemaakt worden! Wees hier voorzichtig mee!</b>
                  </p>
                  {(this.state.delete_update) ? <Loader /> : <Button className="btn btn-danger" onClick={this.delete}>Verwijder</Button>}
                </Card>
                <Col md={1} />
              </Row>
            )}

          </div>
        ) : ('')}
      </div>
    )
  }

  setPayed() {
    this.setState({
      payment_update: true
    });

    axios.post("/api/reservation/" + this.props.match.params.reservation_id + "/update", {
      payed: "true"
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Reservatie bijgewerkt!");
        const reservation = this.state.reservation;
        reservation.payed = true;

        this.setState({
          reservation: reservation
        })
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de reservatie niet bijwerken!");
    }).finally(() => {
      this.setState({
        payment_update: false
      });
    })
  }

  setUnpayed() {
    this.setState({
      payment_update: true
    });

    axios.post("/api/reservation/" + this.props.match.params.reservation_id + "/update", {
      payed: "false"
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Reservatie bijgewerkt!");
        const reservation = this.state.reservation;
        reservation.payed = false;

        this.setState({
          reservation: reservation,
        })
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de reservatie niet bijwerken!");
    }).finally(() => {
      this.setState({
        payment_update: false
      });
    })
  }

  allowRefund() {
    this.setState({
      payment_update: true
    });

    axios.post("/api/reservation/" + this.props.match.params.reservation_id + "/refund", {})
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          toast.success("Reservatie bijgewerkt!");
          const reservation = this.state.reservation;
          reservation.refundable = true;

          this.setState({
            reservation: reservation
          })
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de reservatie niet bijwerken!");
      }).finally(() => {
        this.setState({
          payment_update: false
        });
      })
  }

  delete() {
    this.setState({
      delete_update: true
    });

    axios.post("/api/reservation/" + this.props.match.params.reservation_id + "/delete", {})
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
          this.setState({
            delete_update: false
          });
        } else {
          toast.success("Reservatie verwijderd!");
          this.props.history.push("/show/" + this.state.show.id);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de reservatie niet verwijderen!");
        this.setState({
          delete_update: false
        });
      })
  }

  resendConfirmation() {
    this.setState({
      resend_update: true
    });

    axios.post("/api/reservation/" + this.props.match.params.reservation_id + "/resend", {})
        .then((response) => {
          if (!response.data.success) {
            toast.error(response.data.message);
          } else {
            toast.success("Bevestigingsmail opnieuw verzonden!");
          }
          this.setState({
            resend_update: false
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error("Kon de bevestigingsmail niet opnieuw verzenden!");
          this.setState({
            resend_update: false
          });
        })
  }

  getSubscriptions() {
    return this.state.reservation.subscriptions.map((subscription) => {
      return (
        <tr key={subscription.subscription}>
          <td />
          <td>
            Abonnement {subscription.name}
          </td>
          <td>
            {subscription.text}
          </td>
        </tr>
      )
    })
  }

  getUitpasses() {
    return this.state.reservation.uitpasses.map((uitpass) => {
      return (
        <tr>
          <td />
          <td>
            Korting UITPAS ({uitpass.uitpas})
          </td>
          <td>
            {uitpass.text}
          </td>
        </tr>
      )
    })
  }

  getReductions() {
    return this.state.reservation.reductions.map((reduction) => {
      return (
        <tr>
          <td />
          <td>
            {reduction.name}
          </td>
          <td>
            {reduction.text}
          </td>
        </tr>
      )
    })
  }
}

export default Reservation;
