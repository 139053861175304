import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {ToastContainer} from "react-toastify";
import {reactLocalStorage} from 'reactjs-localstorage';
import {UserContext} from "./context/UserContext";

import SessionThread from "./threads/SessionThread";
import PermissionThread from "./threads/PermissionThread";
import NotificationThread from "./threads/NotificationThread";

import Header from "./components/Header";
import NotificationButton from "./components/NotificationButton";

// Not authenticated
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPass from './pages/auth/ForgotPass';
import PassReset from "./pages/auth/PassReset";

// General
import Home from './pages/Home';
import Calendar from "./pages/Calendar";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import BarList from './pages/BarList';
import CovidCalendar from './pages/CovidCalendar';

// Admin
import Members from './pages/admin/Members';
import NewMembers from './pages/admin/NewMembers';
import BlockedMembers from './pages/admin/BlockedMembers';
import Newslist from "./pages/admin/Newslist";
import Tasks from './pages/Tasks';

// Reservations
import Productions from "./pages/reservations/Productions";
import Shows from "./pages/reservations/Shows";
import Show from "./pages/reservations/Show";
import Reservation from "./pages/reservations/Reservation";
import ManualReservation from "./pages/reservations/ManualReservation";
import Reductions from "./pages/reservations/Reductions";
import Subscriptions from "./pages/reservations/Subscriptions";
import Subscription from "./pages/reservations/Subscription";
import SeatingPlan from "./pages/reservations/SeatingPlan";

// WebAdmin
import Newsletter from "./pages/admin/Newsletter";
import Mailing from './pages/admin/Mailing';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    let user = reactLocalStorage.getObject('user');

    if (!user || !user.id) {
      user = null;
    }

    this.fetchUser = this.fetchUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.updateSettingsToken = this.updateSettingsToken.bind(this);
    this.updatePermissions = this.updatePermissions.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);

    this.state = {
      user: user,
      login: this.login,
      logout: this.logout
    };
  }

  componentDidMount() {
    this.sessionThread = new SessionThread(this.fetchUser, this.logout, this.updateSettingsToken);
    this.permissionThread = new PermissionThread(this.fetchUser, this.updatePermissions);
    this.notificationThread = new NotificationThread(this.fetchUser, this.updateNotifications);
  }

  componentWillUnmount() {
    if (this.sessionThread) this.sessionThread.destroy();
    if (this.permissionThread) this.permissionThread.destroy();
    if (this.notificationThread) this.notificationThread.destroy();
  }

  render() {
    const classes = "rootDiv " + (isMobile ? "mobile-view" : "desktop-view");

    return (
      <UserContext.Provider value={this.state}>
        <div className={classes}>
          {this.getPages()}
          <ToastContainer/>
        </div>
      </UserContext.Provider>
    );
  }

  getPages() {
    return (
      <UserContext.Consumer>
        {({user, logout, login}) => ((user) ? (
          <Router>

            <header>
              <Header user={user} logout={logout} />
            </header>

            <main>

              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/calendar" component={Calendar}/>
                <Route exact path="/profile" component={Profile}/>
                <Route path="/profile/:user_id" component={Profile}/>
                <Route path="/notifications" component={() => <Notifications updateNotifications={this.updateNotifications} />}/>
                <Route path="/barlist/:production" component={(props) => <BarList match={props.match} user={user} />}/>
                <Route path="/covidcalendar/:group" component={CovidCalendar}/>

                <Route exact path="/admin/members" component={Members}/>
                <Route exact path="/admin/new_members" component={NewMembers}/>
                <Route exact path="/admin/blocked_members" component={BlockedMembers}/>
                <Route exact path="/admin/newslist" component={Newslist}/>

                <Route exact path="/productions" component={Productions}/>
                <Route path="/production/:show_code" component={Shows}/>
                <Route path="/show/:show_id" component={Show}/>
                <Route path="/reservation/:reservation_id" component={(props) => <Reservation match={props.match} user={user} />}/>
                <Route path="/manual_reservation/:show_id" component={ManualReservation}/>
                <Route exact path="/reductions" component={Reductions}/>
                <Route exact path="/subscriptions" component={Subscriptions}/>
                <Route exact path="/subscription/:subscription_id" component={Subscription}/>
                <Route exact path="/seating_plan/:show_id" component={SeatingPlan}/>

                <Route exact path="/admin/newsletter" component={Newsletter}/>
                <Route exact path="/admin/mailing" component={Mailing}/>
                <Route exact path="/admin/tasks" component={Tasks}/>

                <Route component={NotFoundRedirect} />

              </Switch>

            </main>

            <NotificationButton />

          </Router>
        ) : (
          <Router>
            <Switch>
              <Route exact path="/" component={() => <Login onLogin={login} />}/>
              <Route exact path="/register" component={Register}/>
              <Route exact path="/forgotpass" component={ForgotPass}/>
              <Route path="/resetpass/:reset_id" component={PassReset}/>
              <Route component={NotFoundRedirect} />
            </Switch>
          </Router>
        ))}
      </UserContext.Consumer>
    )
  }

  fetchUser() {
    return this.state.user;
  }

  updateSettingsToken(token) {
    let user = this.state.user;
    user.settings_token = token;
    this.setState({
      user: user
    });
  }

  updatePermissions(permissions) {
    let user = this.state.user;
    user.permissions = permissions;
    this.setState({
      user: user
    });
  }

  updateNotifications(notifications) {
    let user = this.state.user;
    user.notifications = notifications;
    this.setState({
      user: user
    });
  }

  login(user) {
    reactLocalStorage.setObject("user", user);
    this.setState({
      user: user
    });
  }

  logout() {
    reactLocalStorage.setObject("user", {});
    this.setState({
      user: null
    });
  }
}

function NotFoundRedirect() {
  return (<Redirect to="/" />);
}

export default App;
