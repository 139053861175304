import React from 'react';
import {Link, withRouter} from "react-router-dom";
import axios from 'axios';
import {toast} from 'react-toastify';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faHome,
  faEdit,
  faCog,
  faNewspaper,
  faUser,
  faUsers,
  faFlag,
  faMoneyBill,
  faGlobe,
  faBell,
  faPowerOff,
  faTicketAlt,
  faBeer,
  faMailBulk, faList
} from '@fortawesome/free-solid-svg-icons'

import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './Header.css';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false
    };

    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.logout = this.logout.bind(this);
    this.openYerma = this.openYerma.bind(this);

    this.adminHeader = [<FontAwesomeIcon key="admin_icon" icon={faEdit} className="mr-2"/>, 'Administrator'];
    this.reserveHeader = [<FontAwesomeIcon key="reserve_icon" icon={faFlag} className="mr-2"/>, 'Reservaties'];
    this.webAdminHeader = [<FontAwesomeIcon key="web_admin_icon" icon={faGlobe} className="mr-2"/>, 'Web Admin'];
  }

  setNavExpanded(expanded) {
    this.setState({navExpanded: expanded});
  }

  closeNav() {
    this.setState({navExpanded: false});
  }

  toGticketing() {
    window.open("https://portal.gticketing.com", "_blank");
    this.setState({navExpanded: false});
  }

  render() {
    const profileHeader = [<FontAwesomeIcon key="profile_icon" icon={faUser} className="mr-2"/>, 'Profiel'];
    const barListHeader = [<FontAwesomeIcon key="bar_list_icon" icon={faBeer} className="mr-2"/>, 'Taplijst'];
    //const calendarHeader = [<FontAwesomeIcon key="calendar_icon" icon={faCalendar} className="mr-2"/>, 'Kalender'];

    return (
      <Navbar bg="dark" variant="dark" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} onSelect={this.closeNav}>
        <Navbar.Brand as={Link} to="/" href="#home">
            TDM Ledenzone
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="mr-3">
            <Nav.Link as={Link} to="/" onClick={this.closeNav}><FontAwesomeIcon icon={faHome} className="mr-2"/>Startpagina</Nav.Link>

              { /*
              <NavDropdown title={calendarHeader} id="basic-nav-dropdown">
                  {this.getCalendar()}
              </NavDropdown>
              */ }

            <NavDropdown title={barListHeader} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/barlist/HILTON" onSelect={this.closeNav}>
                Camping Hilton
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/barlist/ANDERSLAND" onSelect={this.closeNav}>
                Andersland
              </NavDropdown.Item>
            </NavDropdown>

            {this.getTasksMenu()}
            {this.getAdminMenu()}
            {this.getReservationMenu()}
            {this.getWebadminMenu()}

            <NavDropdown title={profileHeader} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/profile" onSelect={this.closeNav}>
                <FontAwesomeIcon icon={faUser} className="mr-2"/>Mijn Profiel
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/notifications" onSelect={this.closeNav}>
                <FontAwesomeIcon icon={faBell} className="mr-2"/>Notificaties
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.logout} onSelect={this.closeNav}>
                <FontAwesomeIcon icon={faPowerOff} className="mr-2"/>Afmelden
              </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  getCalendar() {
      return this.props.user && this.props.user.groups && this.props.user.groups.map(group => (
          <NavDropdown.Item key={'covid-calendar-' + group.group_id} as={Link} to={"/covidcalendar/" + group.group_id} onSelect={this.closeNav}>
              { group.name }
          </NavDropdown.Item>
      ))
  }

  getAdminMenu() {
    return (this.props.user && this.props.user.permissions && this.props.user.permissions.indexOf("administrator") > -1) ? (
      <NavDropdown title={this.adminHeader} id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/admin/members" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faCog} className="mr-2"/>Overzicht Leden
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/new_members" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faCog} className="mr-2"/>Nieuwe Leden
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/blocked_members" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faCog} className="mr-2"/>Geblokkeerde Leden
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/newslist" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faNewspaper} className="mr-2"/>Lijst Nieuwsbrief
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/mailing" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faMailBulk} className="mr-2"/>Mail Versturen
          </NavDropdown.Item>
          { /*
          <NavDropdown.Item as={Link} to="/admin/groups" onSelect={this.closeNav}>
              <FontAwesomeIcon icon={faUsers} className="mr-2"/>Groepen
          </NavDropdown.Item>
          */ }
      </NavDropdown>
    ) : (
      ''
    )
  }

  getReservationMenu() {
    return (this.props.user && this.props.user.permissions && this.props.user.permissions.indexOf("administrator") > -1) ? (
        <Nav.Link as={Link} to="" onClick={this.toGticketing}><FontAwesomeIcon key="reserve_icon" icon={faFlag} className="mr-2"/>Reservaties</Nav.Link>
    ) : (
      ''
    )
  }

  getWebadminMenu() {
    return (this.props.user && this.props.user.permissions && this.props.user.permissions.indexOf("webadmin") > -1) ? (
      <NavDropdown title={this.webAdminHeader} id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to="/admin/newsletter" onSelect={this.closeNav}>
          <FontAwesomeIcon icon={faNewspaper} className="mr-2"/>Overzicht Nieuwsbrief
        </NavDropdown.Item>
      </NavDropdown>
    ) : (
      ''
    )
  }

  getTasksMenu() {
    return (this.props.user && this.props.user.permissions && this.props.user.permissions.indexOf("administrator") > -1) ? (
      <Nav.Link as={Link} to="/admin/tasks" onClick={this.closeNav}><FontAwesomeIcon icon={faList} className="mr-2"/>Taken</Nav.Link>
    ) : (
      ''
    )
  }

  logout() {
    axios.post('/api/logout', {}).then(() => {
      this.props.logout();
      this.props.history.push("/");
    }).catch((error) => {
      console.log(error);
      toast.error("Kon niet uitloggen, probeer nogmaals!");
    });
  }

  openYerma() {
    window.open("https://demoedertaal-my.sharepoint.com/:x:/g/personal/tdm_demoedertaal_onmicrosoft_com/EcxzIuvbK-BCvIDUCOZw4ukBzunLXFBlzXOtuCYEsEr9FA?e=9h37qZ", '_blank');
    this.closeNav();
  }
}

export default withRouter(Header);
