import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Row from "react-bootstrap/Row";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class ForgotPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pending: false
    };

    this.changeMail = this.changeMail.bind(this);
    this.request = this.request.bind(this);
  }

  render() {
    return (
      <Row>
        <Card className="login-box" md={4}>
          <h1>TDM Ledenzone</h1>
          <p>Geef je email op om je wachtwoord opnieuw in te stellen.</p>

          <form onSubmit={this.request}>
            <div className="form-group">
              <input id="email" name="email" type="email" value={this.state.email} onChange={this.changeMail} className="form-control" placeholder="E-mailadres" autoComplete="email" required/>
            </div>

            <div className="form-group">
              {(this.state.pending) ? (
                <Loader />
              ) : (
                <button className="btn btn-primary btn-block btn-flat">
                  Aanvragen
                </button>
              )}
            </div>
          </form>

          <div className="d-flex justify-content-between">
            <Link to="/">Aanmelden</Link>
            <Link to="/register">Registreren</Link>
          </div>
        </Card>
      </Row>
    );
  }

  changeMail(event) {
    this.setState({email: event.target.value});
  }

  async request(e) {
    e.preventDefault();

    if (!this.state.email || this.state.email === "") {
      toast.error("Gelieve een mail in te vullen!");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post("/api/forgotpass", {
      email: this.state.email,
      pass: this.state.pass
    }).then((response) => {
      if (response.data.success) {
        toast.success("Indien dit e-mailadres gekend is zal u kortelings een mail ontvangen om je wachtwoord te resetten.");
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het aanvragen van een wachtwoord herstel. Indien dit blijft gebeuren gelieve dit te melden!");
    }).finally(() => {
      this.setState({
        pending: false
      });
    });
  }
}

export default ForgotPass;
