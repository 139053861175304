import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Productions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productions: null
    };
  }

  componentDidMount() {
    this.fetchProductions();
  }

  fetchProductions() {
    axios.get("/api/productions")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            productions: response.data.productions
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de producties niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Producties</h1>
            <p>Selecteer hier een Productie voor welke je alle voorstellingen wilt bekijken.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.productions) ? (
              <div className="table-list">
                {this.getProductions(this.state.productions)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getProductions(productions) {
    return productions.map((production) => {
      const url = "/production/" + production.code;

      return (
        <div key={production.code} className="d-flex justify-content-between flex-with-buttons">
          <p>{ production.name }</p>
          <div>
            <Button as={Link} to={url} className="btn btn-info mr-3">Bekijk Voorstellingen</Button>
          </div>
        </div>
      )
    })
  }
}

export default Productions;