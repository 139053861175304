import axios from 'axios';
import {toast} from "react-toastify";

class SessionThread {
  constructor(fetchUser, logout, updateSettingsToken) {
    this.fetchUser = fetchUser;
    this.logout = logout;
    this.updateSettingsToken = updateSettingsToken;

    this.interval = setInterval(this.verify.bind(this), 60 * 1000);
    this.failedAttempts = 0;

    this.verify();
  }

  destroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  verify() {
    const user = this.fetchUser();

    if (user) {
      axios.post('/api/auth_verify', {
        password_token: user.password_token,
        settings_token: user.settings_token
      }).then((response) => {
        if (!response.data.success) {
          this.failedAttempts++;
        } else {
          if (response.data.logout) {
            this.logout();
          } else if (response.data.banned) {
            toast.error("Je hebt niet langer toegang tot de ledenzone!");
            this.logout();
          } else if (response.data.settingsToken) {
            this.updateSettingsToken(response.data.settingsToken);
          }
          this.failedAttempts = 0;
        }
      }).catch((error) => {
        console.log(error);
        this.failedAttempts++;
      }).finally(() => {
        if (this.failedAttempts > 10) {
          this.logout();
        }
      })
    }
  }
}

export default SessionThread;