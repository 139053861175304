import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {CopyToClipboard} from "react-copy-to-clipboard";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Newslist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: null,
      copylist: null
    };
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/newslist")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            list: response.data.list,
            copylist: response.data.copylist
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de lijst niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Nieuwsbrief</h1>
            <p>
              Hier vind je een lijst van iedereen die zich heeft ingeschreven voor de nieuwsbrief.
              Dit kan zowel gedaan zijn via de optie op de website startpagina of tijdens het bestellen van tickets.
            </p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.list) ? (
              <div>
                <CopyToClipboard text={this.state.copylist} onCopy={() => toast.success("Gekopiëerd!")}>
                  <Button variant="success">Kopiëren in mail formaat</Button>
                </CopyToClipboard>
                <div className="table-list">
                  {this.mapList()}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  mapList() {
    let lines = this.state.list.split('<br>');
    return lines.map((line) => (line !== "") ? <div>{line}</div> : '');
  }
}

export default Newslist;