import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/nl';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class ManualReservation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: null,
      data: {
        name: '',
        firstname: '',
        street: '',
        postal: '',
        city: '',
        mail: '',
        phone: '',
        tickets: 1,
        remarks: ''
      },
      pending: false
    };

    this.changeName = this.changeName.bind(this);
    this.changeFirstname = this.changeFirstname.bind(this);
    this.changeStreet = this.changeStreet.bind(this);
    this.changePostal = this.changePostal.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeMail = this.changeMail.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeTickets = this.changeTickets.bind(this);
    this.changeRemarks = this.changeRemarks.bind(this);
    this.reserve = this.reserve.bind(this);
  }

  componentDidMount() {
    this.fetchShow();
  }

  fetchShow() {
    axios.get("/api/manual_reservation/" + this.props.match.params.show_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            show: response.data.show
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de show niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Manuele Reservatie</h1>
            {(this.state.show) ? (<h4> {this.state.show.name} - <Moment tz="Europe/Brussels" format="DD-MM-YYYY HH:mm">{Number(this.state.show.show_time)}</Moment></h4>) : ''}
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.show) ? (
              <form onSubmit={this.reserve}>
                <h4>Gegevens reservatie</h4>

                <div className="form-group">
                  <label data-for="name">Naam</label>
                  <input key="name" name="name" type="text" value={this.state.data.name} onChange={this.changeName} className="form-control" placeholder="Naam" required/>
                </div>

                <div className="form-group">
                  <label data-for="firstname">Voornaam</label>
                  <input key="firstname" name="firstname" type="text" value={this.state.data.firstname} onChange={this.changeFirstname} className="form-control" placeholder="Voornaam" required/>
                </div>

                <div className="form-group">
                  <label data-for="street">Adres</label>
                  <input key="street" name="street" type="text" value={this.state.data.street} onChange={this.changeStreet} className="form-control" placeholder="Adres" required/>
                </div>

                <div className="form-group">
                  <label data-for="postal">Postcode</label>
                  <input key="postal" name="postal" type="text" value={this.state.data.postal} onChange={this.changePostal} className="form-control" placeholder="Postcode" required/>
                </div>

                <div className="form-group">
                  <label data-for="city">Stad</label>
                  <input key="city" name="city" type="text" value={this.state.data.city} onChange={this.changeCity} className="form-control" placeholder="Stad" required/>
                </div>

                <div className="form-group">
                  <label data-for="mail">E-mailadres</label>
                  <input key="mail" name="mail" type="email" value={this.state.data.mail} onChange={this.changeMail} className="form-control" placeholder="mail" required/>
                </div>

                <div className="form-group">
                  <label data-for="phone">Telefoon</label>
                  <input key="phone" name="phone" type="phone" value={this.state.data.phone} onChange={this.changePhone} className="form-control" placeholder="phone" required/>
                </div>

                <div className="form-group">
                  <label data-for="tickets">Tickets</label>
                  <select key="tickets" name="tickets" value={this.state.data.tickets} onChange={this.changeTickets} className="form-control" required>
                    {this.getTicketOptions()}
                  </select>
                </div>

                <div className="form-group">
                  <label data-for="remarks">Opmerkingen</label>
                  <textarea key="remarks" name="remarks" value={this.state.data.remarks} onChange={this.changeRemarks} className="form-control" />
                </div>

                <div className="form-group">
                  {(this.state.pending) ? (
                    <Loader />
                  ) : (
                    <button className="btn btn-primary btn-flat">
                      Reserveer
                    </button>
                  )}
                </div>

              </form>
            ) : (<Loader />)}
          </Card>
        </Row>
      </div>
    )
  }

  getTicketOptions() {
    const options = [];

    for (let i = 1; i <= this.state.show.tickets; i++) {
      options.push(<option key={i}>{i}</option>)
    }

    return options;
  }

  changeName(event) {
    const data = this.state.data;
    data.name = event.target.value;
    this.setState({data: data});
  }

  changeFirstname(event) {
    const data = this.state.data;
    data.firstname = event.target.value;
    this.setState({data: data});
  }

  changeStreet(event) {
    const data = this.state.data;
    data.street = event.target.value;
    this.setState({data: data});
  }

  changePostal(event) {
    const data = this.state.data;
    data.postal = event.target.value;
    this.setState({data: data});
  }

  changeCity(event) {
    const data = this.state.data;
    data.city = event.target.value;
    this.setState({data: data});
  }

  changeMail(event) {
    const data = this.state.data;
    data.mail = event.target.value;
    this.setState({data: data});
  }

  changePhone(event) {
    const data = this.state.data;
    data.phone = event.target.value;
    this.setState({data: data});
  }

  changeTickets(event) {
    const data = this.state.data;
    data.tickets = event.target.value;
    this.setState({data: data});
  }

  changeRemarks(event) {
    const data = this.state.data;
    data.remarks = event.target.value;
    this.setState({data: data});
  }

  async reserve(event) {
    event.preventDefault();

    if (!this.state.data.name || this.state.data.name === "") {
      toast.error("Gelieve een naam in te vullen!");
      return;
    }

    if (!this.state.data.firstname || this.state.data.firstname === "") {
      toast.error("Gelieve een voornaam in te vullen!");
      return;
    }

    if (!this.state.data.street || this.state.data.street === "") {
      toast.error("Gelieve een adres in te vullen!");
      return;
    }

    if (!this.state.data.postal || this.state.data.postal === "") {
      toast.error("Gelieve een postcode in te vullen!");
      return;
    }

    if (!this.state.data.city || this.state.data.city === "") {
      toast.error("Gelieve een stad in te vullen!");
      return;
    }

    if (!this.state.data.mail || this.state.data.mail === "") {
      toast.error("Gelieve een e-mailadres in te vullen!");
      return;
    }

    if (!this.state.data.phone || this.state.data.phone === "") {
      toast.error("Gelieve een telefoon in te vullen!");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post("/api/manual_reservation/" + this.props.match.params.show_id, {
      name: this.state.data.name,
      firstname: this.state.data.firstname,
      street: this.state.data.street,
      postal: this.state.data.postal,
      city: this.state.data.city,
      email: this.state.data.mail,
      phone: this.state.data.phone,
      tickets: this.state.data.tickets,
      remarks: this.state.data.remarks
    }).then((response) => {
      if (response.data.success) {
        toast.success("Reservatie succesvol aangemaakt!");
        this.props.history.push("/reservation/" + response.data.id);
      } else {
        toast.error(response.data.message);
        this.setState({
          pending: false
        });
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het aanmaken van de reservatie.");
      this.setState({
        pending: false
      });
    })
  }
}

export default ManualReservation;