import React from 'react';

import './Card.css';

class Card extends React.Component {
  render() {
    const classes = (this.props.md) ? ("col-md-" + this.props.md + " card-box " + ((this.props.className) ? this.props.className : '')) : "card-box "  + ((this.props.className) ? this.props.className : '');

    return (
      <div id={this.props.id} className={classes}>
        {this.props.children}
      </div>
    );
  }
}

export default Card;