import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Card from "../components/Card";
import ComingSoon from "../assets/images/ComingSoon.png";

class Calendar extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10} className="text-center">
            <img src={ComingSoon} alt="Coming soon" />
          </Card>
        </Row>
      </div>
    )
  }
}

export default Calendar;