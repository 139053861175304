import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Newsletter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: null,
      amount: 0
    };
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/newsletter")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            list: response.data.list,
            amount: response.data.count
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de lijst niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Nieuwsbrief</h1>
            <p>
              Amount to send: {this.state.amount}
            </p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.list) ? (
              <div className="table-list">
                {this.mapList()}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  mapList() {
    let lines = this.state.list.split('<br>');
    return lines.map((line) => (line !== "") ? <div>{line}</div> : '');
  }
}

export default Newsletter;