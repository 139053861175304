import React from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {isMobile} from 'react-device-detect';
import Moment from 'react-moment';

import {UserContext} from '../context/UserContext';

import Button from 'react-bootstrap/Button';

import Card from '../components/Card';
import Loader from '../components/Loader';

import './BarList.css';

class BarList extends React.Component {

  constructor(props) {
    super(props);

    const user = this.props.user;

    this.state = {
      shows: null,
      entries: null,
      pending_update: false,
      for_user: user.id,
      users: null
    };

    this.changeUser = this.changeUser.bind(this);
    this.claimEntry = this.claimEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.save = this.save.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.fetchBarList();

    if (this.props.user.permissions.indexOf('administrator') !== -1) {
      this.fetchMembers();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.production !== prevProps.match.params.production) {
      this.setState({
        shows: null,
        entries: null,
      });
      this.fetchBarList();
    }
  }

  fetchBarList() {
    axios.get('/api/barlist/' + this.props.match.params.production)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {

          let shows = response.data.shows;

          shows.forEach(show => {
            show.entries = [4];

            for (let i = 0; i < 5; i++) {
              show.entries[i] = response.data.entries.find(entry => {
                return entry.show_id === show.id && entry.entry_id === i
              });
            }
          });

          this.setState({
            shows: response.data.shows
          });
        }
      }).catch((error) => {
      console.log(error);
      toast.error('Kon de taplijst niet ophalen.');
    });
  }

  fetchMembers() {
    axios.get('/api/admin/members')
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {

          const users = response.data.users;

          users.sort((a, b) => {
            const nameA = a.name.toUpperCase() + ' ' + a.first_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase() + ' ' + b.first_name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })

          this.setState({
            users
          }, this.filter);
        }
      }).catch((error) => {
      console.log(error);
      toast.error('Kon de leden niet ophalen.');
    });
  }

  getButtons(user) {
    let buttons = [];

    buttons.push(<Button onClick={() => this.save()} variant="success">Opslaan</Button>);

    (!isMobile && user.permissions.indexOf('administrator') > -1 && buttons.push(<Button style={{marginLeft: '1rem'}}
                                                                                         onClick={this.print}
                                                                                         variant="success">Print</Button>));

    return buttons;
  }

  render() {

    return (
      <UserContext.Consumer>
        {({user}) => (

          <div>
            <Card className="d-flex flex-with-buttons justify-content-sm-between d-print-none">
              <h1><strong>Taplijst - {(this.state.shows) ? this.state.shows[0].name : ''}</strong></h1>
              {(this.state.pending_update) ? <Loader/> :
                <div>
                  {this.getButtons(user)}
                </div>
              }
            </Card>
            <Card className="d-print-none">
              <p>
                Om een plaats op de taplijst te selecteren klik je op het respectievelijke vakje. Nadat je alle gekozen
                vakjes geselecteerd hebt klik je op opslaan om je keuze's op te slaan.
              </p>
            </Card>

            {user.permissions.indexOf('administrator') > -1 && (
              <Card className="d-print-none">
                <p>
                  Kies voor wie je de taplijst wilt aanpassen
                </p>
                <select value={this.state.for_user} onChange={(event) => this.changeUser(event)}>
                  {
                    this.state.users?.map((user) =>
                      <option value={user.user_id} key={user.user_id}>{user.name} {user.first_name}</option>
                    )
                  }
                </select>
              </Card>
            )}

            <Card className="barListContainer">
              <div key="barList" id="barList">
                {this.getBarList()}
              </div>
            </Card>
          </div>

        )}

      </UserContext.Consumer>
    )
  }

  getBarList() {
    return (this.state.shows) ? (
      <table className="barList">
        <thead className="header">
        <tr>
          <th className="small-column">Dag</th>
          <th className="small-column">Datum</th>
          <th className="small-column">Startuur</th>
          <th className="column">
            Tapper 1
            <br/>
            Ten laatste 1u15 op voorhand aanwezig
          </th>
          <th className="column">
            Tapper 2
            <br/>
            Ten laatste 1u00 op voorhand aanwezig
          </th>
          <th className="column">
            Tapper 3
            <br/>
            Ten laatste 45min op voorhand aanwezig
          </th>
          <th className="column">
            Kassa (bestuur)
            <br/>
            Ten laatste 1u15 op voorhand aanwezig
          </th>
          <th className="small-column">
            Sluiten (bestuur)
          </th>
        </tr>
        </thead>

        <tbody>
        <UserContext.Consumer>
          {({user}) =>
            this.getList(user)
          }
        </UserContext.Consumer>
        </tbody>

      </table>
    ) : <Loader/>

  }

  getList(user) {
    return this.state.shows.map(show => {

      let row = [];

      row.push(
        <td key={'DAY_' + show.id} className="small-column">
          <Moment tz="Europe/Brussels" format="dddd">{Number(show.show_time)}</Moment>
        </td>,
        <td key={'DATE_' + show.id} className="small-column">
          <Moment tz="Europe/Brussels" format="DD-MM-YYYY">{Number(show.show_time)}</Moment>
        </td>,
        <td key={'TIME_' + show.id} className="small-column">
          <Moment tz="Europe/Brussels" format="HH:mm">{Number(show.show_time)}</Moment>
        </td>
      );

      const order = [1, 2, 3, 0, 4]
      for (let j = 0; j < 5; j++) {
        let i = order[j];
        let classes = (i !== 4) ? 'column' : 'small-column';
        let onclick;

        if (show.entries[i]) {
          if (Number(show.entries[i].user_id) === Number(this.state.for_user)) {
            classes += ' claimedOwn';
            onclick = (() => this.removeEntry(show.id, i));
          } else {
            classes += ' claimed';
          }
        } else {
          onclick = (() => this.claimEntry(show.id, i, user));
        }

        row.push(
          <td key={'ENTRY_' + show.id + '_' + i} className={classes} onClick={onclick}>
            {(show.entries[i]) ? show.entries[i].name : ''}
          </td>
        )
      }

      return (
        <tr key={'SHOW_' + show.id}>
          {row}
        </tr>
      );
    });
  }

  changeUser(event) {
    if (event.target.value) {
      this.setState({
        for_user: event.target.value
      })
    }
  }

  claimEntry(showId, entryId, loggedIn) {

    let user;

    if (this.state.users) {
      user = this.state.users.find(user => {
        return user.user_id === this.state.for_user
      });
    } else {
      user = loggedIn;
    }

    let shows = this.state.shows;
    let show = shows.find(show => {
      return show.id === showId
    });

    if (show) {
      show.entries[entryId] = {
        entry_id: entryId,
        name: user.name + ' ' + user.first_name,
        show_id: showId,
        user_id: user.user_id || user.id
      };
    }

    this.setState({
      shows: shows
    });
  }

  removeEntry(showId, entryId) {
    let shows = this.state.shows;
    let show = shows.find(show => {
      return show.id === showId
    });

    if (show) {
      show.entries[entryId] = undefined;
    }

    this.setState({
      shows: shows
    });
  }

  save() {
    let entries = [];

    this.state.shows.forEach(show => {
      show.entries.forEach(entry => {
        if (entry && entry.user_id === this.state.for_user) {
          entries.push(entry);
        }
      })
    });

    this.setState({
      pending_update: true
    });

    axios.post('/api/barlist/' + this.props.match.params.production, {
      entries: entries,
      for_user: this.state.for_user
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success('Taplijst opgeslagen!');
      }
    }).catch((error) => {
      console.log(error);
      toast.error('Kon de taplijst niet opslaan!');
    }).finally(() => {
      this.setState({
        pending_update: false
      });
    })
  }

  print() {
    const sTable = document.getElementById('barList').innerHTML;
    const title = 'Taplijst - ' + ((this.state.shows) ? this.state.shows[0].name : '');

    let style = '<style>';
    style = style + '@page { size: landscape; }';
    style = style + 'table {width: 100%;font: 17px Calibri;}';
    style = style + 'table, th, td {border: solid 1px #DDD; border-collapse: collapse;';
    style = style + 'padding: 2px 3px;text-align: center;}';
    style = style + '</style>';

    // CREATE A WINDOW OBJECT.
    const win = window.open('', '', 'height=700,width=1000');

    win.document.write('<html><head>');
    win.document.write('<title>' + title + '</title>');
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');

    win.document.close(); 	// CLOSE THE CURRENT WINDOW.

    win.print();    // PRINT THE CONTENTS.
  }
}

export default BarList;
