import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import {Button} from "react-bootstrap";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Reductions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reductions: null,
      productions: null,
      data: {
        code: '',
        name: '',
        production: 'ALL',
        early_bird: 'true'
      },
      pending_add: false
    };

    this.randomCode = this.randomCode.bind(this);
    this.changeCode = this.changeCode.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeProduction = this.changeProduction.bind(this);
    this.changeEarlyBird = this.changeEarlyBird.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.fetchReductions();
  }

  fetchReductions() {
    axios.get("/api/reductions")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            reductions: response.data.reductions,
            productions: response.data.productions
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de kortingen niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Kortingen</h1>
            <p>Hier kan je nieuwe kortingscodes aanmaken of een overzicht bekijken van alle codes</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <form onSubmit={this.submit}>
              <h4>Nieuwe Korting</h4>

              <div className="form-group">
                <label data-for="code">Code</label>
                <input key="code" name="code" type="text" value={this.state.data.code} onChange={this.changeCode} className="form-control" placeholder="Code" required/>
                <Button className="mt-3" onClick={this.randomCode} >Random Code</Button>
              </div>

              <div className="form-group">
                <label data-for="name">Code</label>
                <input key="name" name="name" type="text" value={this.state.data.name} onChange={this.changeName} className="form-control" placeholder="Naam" required/>
              </div>

              <div className="form-group">
                <label data-for="production">Productie</label>
                <select key="production" name="production" value={this.state.data.production} onChange={this.changeProduction} className="form-control" required>
                  {this.getProductionOptions()}
                </select>
              </div>

              <div className="form-group">
                <label data-for="early_bird">Vroegboekkorting</label>
                <select key="production" name="production" value={this.state.data.early_bird} onChange={this.changeEarlyBird} className="form-control" required>
                  <option value="true">Vroegboekkorting toestaan</option>
                  <option value="false">Geen vroegboekkorting toestaan</option>
                </select>
              </div>

              <div className="form-group">
                {(this.state.pending_add) ? (
                  <Loader />
                ) : (
                  <button className="btn btn-primary btn-flat">
                    Toevoegen
                  </button>
                )}
              </div>

            </form>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.reductions) ? (
              <div>
                {this.renderTable()}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getProductionOptions() {
    const options = [
      <option key="OPT_ALL" value="ALL">Alle voorstellingen</option>
    ];

    if (this.state.productions) {
      this.state.productions.map((production) => {
        return options.push(<option key={"OPT_" + production.code} value={production.code}>{production.code} - {production.name}</option>)
      });
    }

    return options;
  }

  renderTable() {
    const columns = [{
      Header: 'Code',
      accessor: 'id',
    }, {
      Header: 'Naam',
      accessor: 'name',
    }, {
      Header: 'Type',
      accessor: 'typetext',
    }, {
      Header: 'Gebruikt',
      accessor: 'usedtext',
    }, {
      Header: 'Reservatie',
      accessor: 'reservation',
      Cell: props => ((props.value === "-1") ? <span>N/A</span> : <a href={"/reservation/" + props.value}>{props.value}</a>)
    }, {
      Header: 'Extra Info',
      accessor: 'extra',
      minWidth: 300,
      Cell: (props) => {
        if (props.value) {
          let lines = props.value.split('<br />');
          return lines.map((line) => <div>{line}</div>);
        } else {
          return ''
        }
      }
    }];

    return <ReactTable
      data={this.state.reductions}
      columns={columns}
      filterable={true}
      defaultPageSize={10}
      previousText={"Vorige"}
      nextText={"Volgende"}
      loadingText={"Laden..."}
      noDataText={"Geen kortingen gevonden"}
      pageText={"Pagina"}
      ofText={"van"}
      rowsText={"rijen"}
      pageJumpText={"spring naar pagina"}
      rowsSelectorText={"rijen per pagina"}
    />
  }

  randomCode() {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let text = "";

    for (let i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    const data = this.state.data;
    data.code = text;
    this.setState({data: data});
  }

  changeCode(event) {
    const data = this.state.data;
    data.code = event.target.value;
    this.setState({data: data});
  }

  changeName(event) {
    const data = this.state.data;
    data.name = event.target.value;
    this.setState({data: data});
  }

  changeProduction(event) {
    const data = this.state.data;
    data.production = event.target.value;
    this.setState({data: data});
  }

  changeEarlyBird(event) {
    const data = this.state.data;
    data.early_bird = event.target.value;
    this.setState({data: data});
  }

  submit(event) {
    event.preventDefault();

    if (!this.state.data.code || this.state.data.code === "") {
      toast.error("Gelieve een code in te vullen!");
      return;
    }

    if (!this.state.data.name || this.state.data.name === "") {
      toast.error("Gelieve een naam in te vullen!");
      return;
    }

    if (!this.state.data.production || this.state.data.production === "") {
      toast.error("Gelieve een productie te selecteren!");
      return;
    }

    if (!this.state.data.early_bird || this.state.data.early_bird === "") {
      toast.error("Gelieve een vroegboekkorting optie te selecteren!");
      return;
    }

    this.setState({
      pending_add: true
    });

    axios.post("/api/reductions/add", {
      code: this.state.data.code,
      name: this.state.data.name,
      show: this.state.data.production,
      earlyBird: this.state.data.early_bird
    }).then((response) => {
      if (response.data.success) {
        toast.success("Korting succesvol aangemaakt!");
        this.fetchReductions();
        this.setState({
          data: {
            code: '',
            name: '',
            production: 'ALL',
            early_bird: 'true'
          }
        });
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het aanmaken van de korting.");
    }).finally(() => {
      this.setState({
        pending_add: false
      });
    })
  }

}

export default Reductions;